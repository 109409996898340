import type {Message} from '@/api'
import {APP_STATE, SIDEBAR_TABS} from '@/constants'
import type {Item} from '@/features/vaults'
import type {AppState} from '@/types'
import {createSlice} from '@reduxjs/toolkit'

const initialState: {
  appState: AppState
  historyId?: string
  messages: Message[]
  prompt: string
  selectedFiles: Item[]
  selectedSidebarTab: string
  uploadingFiles: (File & {id: string})[]
  uploadingGroup: {id?: string; name?: string}
} = {
  appState: APP_STATE.WAITING_FOR_USER_INPUT,
  historyId: undefined,
  messages: [],
  prompt: '',
  selectedFiles: [],
  selectedSidebarTab: SIDEBAR_TABS.HISTORY,
  uploadingFiles: [],
  uploadingGroup: {},
}

export const currentSlice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload)
    },
    reset: () => initialState,
    resetPrompt: (state) => {
      state.prompt = initialState.prompt
    },
    removeFile: (state, action) => {
      state.selectedFiles = state.selectedFiles.filter(
        (file) => file.path !== action.payload.path,
      )
    },
    selectFile: (state, action) => {
      const file = action.payload
      if (!state.selectedFiles.find((f) => f.path === file.path)) {
        state.selectedFiles.push(file)
      }
    },
    setAppState: (state, action) => {
      state.appState = action.payload
    },
    setHistoryId: (state, action) => {
      state.historyId = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload
    },
    setSelectedSidebarTab: (state, action) => {
      state.selectedSidebarTab = action.payload
    },
    setUploadingFiles: (state, action) => {
      state.uploadingFiles = action.payload
    },
    setUploadingGroup: (state, action) => {
      state.uploadingGroup = action.payload
    },
    removeUploadingFiles: (state, action) => {
      state.uploadingFiles = state.uploadingFiles.filter(
        (file) => !action.payload.includes(file.id),
      )
    },
  },
})
