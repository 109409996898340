import {useGetDatabucketsQuery, useGetUserGroupsQuery} from '@/api'
import {useUploadFiles} from '@/hooks/use-upload-files'
import {
  Dropzone,
  ErrorState,
  Listbox,
  LoadingState,
  Modal,
  TrashIconButton,
  useModal,
} from '@gicortex/nucleus'
import {useState} from 'react'
import {formatFileSize} from './constants'

const PERSONAL_VAULT_OPTION = {
  name: 'Your Vault (default)',
}

export type Group = {id?: string; name?: string}
export const UploadFilesModal = () => {
  const {closeModal} = useModal()
  const [files, setFiles] = useState<File[]>([])
  const {handleUploadFiles, isLoading, error} = useUploadFiles()

  const {
    data: userGroups = [],
    isLoading: isUserGroupsLoading,
    error: userGroupsError,
  } = useGetUserGroupsQuery()

  const {
    data: databuckets = [],
    isLoading: isDatabucketsLoading,
    error: databucketsError,
  } = useGetDatabucketsQuery()

  const [bucketName, setBucketName] = useState<string>()
  const [group, setGroup] = useState<Group>({name: 'Personal'})

  const handleDrop = (acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
  }

  const handleUnselectFile = (fileName: string) => {
    setFiles(files.filter((file) => file.name !== fileName))
  }

  const handleUpload = async () => {
    if (files.length > 0) {
      handleUploadFiles({
        files,
        options: {withToast: false},
        bucketName,
        group,
      })
      closeModal()
    }
  }

  if (error || userGroupsError || databucketsError) {
    return <ErrorState />
  }

  if (isLoading || isUserGroupsLoading || isDatabucketsLoading) {
    return <LoadingState />
  }

  return (
    <Modal>
      <Modal.Title>
        <div className='flex items-center justify-between'>
          <h2 className='text-lg font-bold'>Upload Files</h2>
        </div>
      </Modal.Title>
      <div className='px-4 py-2'>
        <div className='mb-4'>
          <Listbox
            name='databucket'
            label='Data Bucket'
            optionLabelKey='name'
            optionValueKey='name'
            value='name'
            defaultSelected={databuckets[0].name}
            options={databuckets.map((bucket) => {
              return {name: bucket.name}
            })}
            onChange={(selectedOption) => {
              setBucketName(selectedOption.name as string)
            }}
          />
          <Listbox
            name='vault'
            label='Vault'
            optionLabelKey='name'
            optionValueKey='id'
            value='id'
            defaultSelected=''
            options={[
              {...PERSONAL_VAULT_OPTION, id: ''},
              ...userGroups.map((group) => ({
                id: group.id,
                name: group.name,
              })),
            ]}
            onChange={(selectedOption) => {
              if (selectedOption.name === PERSONAL_VAULT_OPTION.name) {
                selectedOption.name = 'Personal'
              }
              setGroup(selectedOption as Group)
            }}
          />
        </div>

        <Dropzone onDrop={handleDrop} />
        {files.length > 0 && (
          <ul className='mt-4'>
            {files.map((file) => (
              <li
                key={file.name}
                className='flex items-center justify-between p-2 bg-gray-100 rounded mb-2'
              >
                <div className='max-w-xs'>
                  <span className='block overflow-hidden whitespace-nowrap overflow-ellipsis'>
                    {file.name}
                  </span>
                  <small className='text-gray-500'>
                    {formatFileSize(file.size)}
                  </small>
                </div>
                <TrashIconButton
                  onClick={() => handleUnselectFile(file.name)}
                  className='text-gray-500'
                />
              </li>
            ))}
          </ul>
        )}
        {error && (
          <p className='text-red-500'>
            Error uploading files. Please try again.
          </p>
        )}
      </div>
      <Modal.Footer
        onSubmit={handleUpload}
        submitButtonProps={{
          text: 'Upload',
        }}
        cancelButtonProps={{
          text: 'Cancel',
          onClick: closeModal,
        }}
      />
    </Modal>
  )
}
