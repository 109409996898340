import type {ChatHistoryLiteResponse} from '@/api'
import {createSlice} from '@reduxjs/toolkit'

export const historySlice = createSlice({
  name: 'history',
  initialState: {
    chats: [] as ChatHistoryLiteResponse[],
  },
  reducers: {
    setChat: (state, action) => {
      state.chats.push(action.payload)
    },
    setChats: (state, action) => {
      state.chats = action.payload
    },
  },
})
