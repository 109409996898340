import {cn} from '@gicortex/nucleus'
import PDFObject from 'pdfobject'
import {useEffect} from 'react'

export interface PdfViewerProps {
  className?: string
  id?: string
  url: string
}

export const PdfViewer = ({
  className,
  id = 'pdf-viewer',
  url,
}: PdfViewerProps) => {
  useEffect(() => {
    PDFObject.embed(url, `#${id}`, {forcePDFJS: true})
  }, [id, url])

  return (
    <div
      id={id}
      className={cn('h-full w-full', className)}
    />
  )
}
