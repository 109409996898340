import {CsvViewer} from '@/components/csv-viewer'
import {PdfViewer} from '@/components/pdf-viewer'
import {layoutSlice} from '@/store/layout'
import type {RootState} from '@/types'
import {
  Row,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  XIconButton,
  cn,
} from '@gicortex/nucleus'
import {useDispatch, useSelector} from 'react-redux'

export const SplitPane = () => {
  const layout = useSelector((state: RootState) => state.layout)
  const dispatch = useDispatch()
  const {selectedSplitPaneTab} = useSelector((state: RootState) => state.layout)

  const changeTab = (tabIndex: number) => {
    dispatch(layoutSlice.actions.setSelectedSplitPaneTab(tabIndex))
  }

  const closeTab = (tabIndex: number) => {
    dispatch(layoutSlice.actions.closeSplitPaneTab(tabIndex))
  }

  return (
    <div className='h-full'>
      <TabGroup
        selectedIndex={selectedSplitPaneTab}
        className='flex flex-col h-full rounded-r-lg bg-white'
      >
        <TabList className='flex border-b'>
          <Row className='flex-grow h-[56px] gap-2 px-3 py-2 overflow-x-auto overflow-y-hidden'>
            {layout.splitPaneTabs.map((tab, index) => (
              <Tab
                className={cn(
                  'flex items-center space-x-2 pl-2 !pr-0 py-0 cursor-pointer',
                  'rounded-md text-sm font-medium',
                  'outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50',
                  layout.selectedSplitPaneTab === index
                    ? 'bg-gray-100 text-gray-800'
                    : 'text-gray-600 hover:bg-indigo-100',
                  'data-[selected]:border-b-0',
                )}
                key={btoa(String(index))}
                onClick={() => changeTab(index)}
                as='div' // Avoid nested button error with XIconButton
              >
                <span className='whitespace-nowrap'>
                  {tab.label ?? `${tab.type.toUpperCase()} ${index + 1}`}
                </span>
                <XIconButton
                  className='text-gray-400 hover:text-gray-800'
                  onClick={() => closeTab(index)}
                />
              </Tab>
            ))}
          </Row>
        </TabList>
        <TabPanels className='flex-grow overflow-auto mt-0'>
          {layout.splitPaneTabs.map((tab, index) => (
            <TabPanel
              key={btoa(String(index))}
              className='h-full !py-0'
            >
              {tab.type === 'pdf' ? (
                <PdfViewer url={tab.url} />
              ) : tab.type === 'csv' ? (
                <CsvViewer url={tab.url} />
              ) : null}
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  )
}
