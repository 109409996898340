import {Icon, Loader, Row} from '@gicortex/nucleus'
import {Bot} from 'lucide-react'

export const AgentWaitingIndicator = () => (
  <Row className='gap-4 items-start'>
    <Icon
      component={Bot}
      size='m'
    />
    <Loader className='h-6 w-6' />
  </Row>
)
