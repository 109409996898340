import {useGetDatabucketFilesQuery} from '@/api'
import {Messages} from '@/components/messages'
import {NewChatButton} from '@/components/new-chat-button'
import {Prompt} from '@/components/prompt'
import {ToggleSidebarButton} from '@/components/toggle-sidebar-button'
import {useUploadFiles} from '@/hooks/use-upload-files'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {
  DropzoneOverlay,
  Row,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  useDropzone,
} from '@gicortex/nucleus'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const PERSONAL_GROUP_ID = 'Personal'

export const Chat = () => {
  const layout = useSelector((state: RootState) => state.layout)
  const dispatch = useDispatch()
  const {handleUploadFiles} = useUploadFiles()
  const {messages} = useSelector((state: RootState) => state.current)
  const messagesRef = useRef<HTMLDivElement | null>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleScroll = () => {
      if (messagesRef.current) {
        messagesRef.current.scrollTo({
          top: messagesRef.current.scrollHeight,
          behavior: 'smooth',
        })
      }
    }

    handleScroll()
  }, [messages])

  // TODO: workaround to automaitcally select the uploaded files since upload does not return them
  const {data: files = []} = useGetDatabucketFilesQuery()
  const [filesToSelect, setFilesToSelect] = useState<File[]>([])

  const handleUpload = async (filesToUpload: File[]) => {
    await handleUploadFiles({files: filesToUpload})
    setFilesToSelect(filesToUpload)
  }

  // TODO: workarounds to automatically select the uploaded files since upload does not return them
  // Only try to upload once all pending files are returned by getFilesQuery
  const shouldSelectFiles = useMemo(() => {
    return (
      filesToSelect &&
      filesToSelect.length > 0 &&
      filesToSelect.every((file) =>
        files.find((f) => f.file === `/${PERSONAL_GROUP_ID}/${file.name}`),
      )
    )
  }, [files, filesToSelect])

  useEffect(() => {
    if (shouldSelectFiles) {
      for (const file of filesToSelect) {
        // Hardcoded to only expect uploads to 'Personal' group for now
        const foundFile = files.find(
          (f) => f.file === `/${PERSONAL_GROUP_ID}/${file.name}`,
        )

        if (foundFile) {
          const [_, groupId, name] = foundFile.file.split('/') // file property is string of form "/groupId/fileName"
          dispatch(
            currentSlice.actions.selectFile({
              bucketName: foundFile.bucket,
              groupId,
              id: foundFile.path,
              file: name,
              path: foundFile.path,
            }),
          )
        }
      }
      setFilesToSelect([])
    }
  }, [files, filesToSelect, dispatch, shouldSelectFiles])

  const {isDragActive, handleDragOver, handleDragLeave, handleDrop} =
    useDropzone({
      onDrop: handleUpload,
    })

  return (
    <TabGroup
      className='grid grid-rows-[auto_1fr_auto] min-w-80 overflow-y-auto w-full'
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <DropzoneOverlay visible={isDragActive} />
      <TabList className='border-b flex'>
        {!layout.showSidebar && (
          <Row className='bg-gray-100 border-r p-2 rounded-tl-lg'>
            <ToggleSidebarButton />
          </Row>
        )}
        <Row className='p-2 w-full'>
          <NewChatButton />
          {/* <IconButton
            icon={FileText}
            size='m'
            onClick={() => {
              dispatch(
                layoutSlice.actions.openInSplitPane({
                  label: 'sample.pdf',
                  type: 'pdf',
                  url: 'https://pdfobject.com/pdf/sample.pdf',
                }),
              )
            }}
            tooltip='Open Sample PDF'
          /> */}
          {/* <IconButton
            icon={Table}
            size='m'
            onClick={() => {
              dispatch(
                layoutSlice.actions.openInSplitPane({
                  label: 'Sample CSV',
                  type: 'csv',
                }),
              )
            }}
            tooltip='Open Sample PDF'
          /> */}
        </Row>
      </TabList>
      <TabPanels className='overflow-y-auto mt-0'>
        <TabPanel className='h-full !py-0 '>
          <div
            className='h-full overflow-y-auto'
            ref={messagesRef}
          >
            <Messages />
          </div>
        </TabPanel>
      </TabPanels>
      <Prompt />
    </TabGroup>
  )
}
