import {authUtil} from '@/utils/auth'
import {Path} from '@gicortex/nucleus'
import {Navigate} from 'react-router-dom'

export const AuthRoute = ({
  page: Page,
}: {
  page: React.ComponentType
}) =>
  authUtil.getTokens().accessToken ? <Page /> : <Navigate to={Path.LOGIN} />
