import {IconButton, useModal} from '@gicortex/nucleus'
import {Paperclip} from 'lucide-react'
import {UploadFilesModal} from '../upload-files-modal'

export const UploadFileButton = () => {
  const {openModal} = useModal()

  return (
    <div className='rounded-s-full'>
      <IconButton
        className='rounded-full -rotate-45'
        icon={Paperclip}
        onClick={() => openModal(<UploadFilesModal />)}
        tooltip='Upload file'
      />
    </div>
  )
}
