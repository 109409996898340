import {uuidUtil} from '../uuid'

const removeUuidPrefix = (fileName: string) => {
  const substrings = fileName.split('.')
  return uuidUtil.isUuid(substrings[0])
    ? substrings.slice(1).join('.')
    : fileName
}

export const fileUtil = {
  removeUuidPrefix,
}
