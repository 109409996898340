import {useGetDatabucketFilesQuery} from '@/api'
import {RefreshIconButton} from '@gicortex/nucleus'

export const RefreshVaultsButton = () => {
  const {refetch, isFetching} = useGetDatabucketFilesQuery()

  return (
    <RefreshIconButton
      isFetching={isFetching}
      refetch={refetch}
      size='xs'
      tooltip='Refresh Vaults'
    />
  )
}
