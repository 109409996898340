import type {RefreshTokenResponse} from '@/api'
import {API_URL, TAG_TYPE} from '@/constants'
import {authUtil} from '@/utils/auth'
import {
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const {accessToken} = authUtil.getTokens()
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

const baseQueryWithReauth:
  | BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
  | Response = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    const {refreshToken} = authUtil.getTokens()
    const authQueryWithRetry = retry(baseQuery, {maxRetries: 3})
    const refreshResult = await authQueryWithRetry(
      {
        url: '/auth',
        method: 'POST',
        body: {refreshToken},
      },
      api,
      extraOptions,
    )
    const tokens = refreshResult.data as RefreshTokenResponse
    if (tokens) {
      authUtil.setTokens(tokens as RefreshTokenResponse)
      return baseQuery(args, api, extraOptions)
    }
    authUtil.removeTokens()
  }
  return result
}

export const baseApi = createApi({
  reducerPath: 'api',
  tagTypes: Object.keys(TAG_TYPE),
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})
