import '@/api/enhanced-api'
import '@/index.css'
import {Router} from '@/router'
import {store} from '@/store'
import {NucleusProvider} from '@gicortex/nucleus'
import '@gicortex/nucleus/style.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'

const rootElement = document.getElementById('root')

if (!rootElement) {
  throw new Error('Root element not found')
}

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <NucleusProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </NucleusProvider>
    </Provider>
  </React.StrictMode>,
)
