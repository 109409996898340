import type {Message} from '@/api'
import {AgentWaitingIndicator} from '@/components/agent-waiting-indicator'
import {APP_STATE, ROLE} from '@/constants'
import {Suggestions} from '@/features/suggestions'
import type {Role, RootState} from '@/types'
import {Col} from '@gicortex/nucleus'
import {useSelector} from 'react-redux'
import {AssistantMessage} from './assistant-message'
import {UserMessage} from './user-message'

export const Messages = () => {
  const current = useSelector((state: RootState) => state.current)

  if (!current.messages.length) {
    return <Suggestions />
  }

  return (
    <Col className='gap-8 max-w-screen-md mx-auto p-8'>
      {/*
      // @ts-ignore */}
      {current.messages.map((message: Message & {source: Role}) => (
        <div key={message.id}>
          {message.source === ROLE.AGENT ||
          message.source === ROLE.Assistant ? (
            // @ts-ignore
            <AssistantMessage message={message} />
          ) : (
            // @ts-ignore
            <UserMessage message={message} />
          )}
        </div>
      ))}
      {current.appState === APP_STATE.WAITING_FOR_AGENT_RESPONSE && (
        <AgentWaitingIndicator />
      )}
    </Col>
  )
}
