import {AuthRoute} from '@/components/auth-route'
import {AppPage} from '@/pages/app'
import {CallbackPage} from '@/pages/callback'
import {LoginPage} from '@/pages/login'
import {LogoutPage} from '@/pages/logout'
import {Path} from '@gicortex/nucleus'
import {Navigate, Route, Routes} from 'react-router-dom'

const authPaths = [
  {path: Path.ROOT, page: AppPage},
  {path: Path.LOGOUT, page: LogoutPage},
]

export const Router = () => (
  <Routes>
    <Route
      element={<LoginPage />}
      path={Path.LOGIN}
    />
    <Route
      element={<CallbackPage />}
      path={Path.CALLBACK}
    />
    {authPaths.map(({path, page}) => (
      <Route
        element={<AuthRoute page={page} />}
        key={path}
        path={path}
      />
    ))}
    <Route
      element={<Navigate to={Path.LOGIN} />}
      path='*'
    />
  </Routes>
)
