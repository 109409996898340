import {LoadingState, Row} from '@gicortex/nucleus'
import {parse} from 'papaparse'
import {useEffect, useState} from 'react'
import Spreadsheet from 'react-spreadsheet'

export interface CsvViewerProps {
  url: string
}

type SpreadsheetRow = {value: string}[]
type SpreadsheetData = SpreadsheetRow[]

export const CsvViewer = ({url}: CsvViewerProps) => {
  const [data, setData] = useState<SpreadsheetData>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    parse(url, {
      download: true,
      complete: (result) => {
        // NOTE @simon An extra empty string is added as an empty item by
        // papaparse (ie. [''] is pushed as the last item by default). We take
        // advantage of this to make an extra empty row in the spreadsheet for
        // the user to run formulas vertically
        const data = result.data as string[][]
        const spreadsheetData: SpreadsheetData = []
        for (const row of data) {
          const spreadsheetRow: SpreadsheetRow = []
          const lastCell = row[row.length - 1]
          for (const cell of row) {
            spreadsheetRow.push({value: cell})
            // NOTE @simon Add an extra empty cell at the end of the row to
            // allow users to run formulas horizontally
            if (cell === lastCell) {
              spreadsheetRow.push({value: ''})
            }
          }
          spreadsheetData.push(spreadsheetRow)
        }
        setData(spreadsheetData)
        setIsLoading(false)
      },
    })
  }, [url])

  if (isLoading) {
    return (
      <Row className='items-center justify-center h-screen'>
        <LoadingState />
      </Row>
    )
  }

  if (!data) {
    return <p>Failed to load data</p>
  }

  return (
    <Spreadsheet
      className='[&_tr:first-child_th]:border-t-0'
      data={data}
    />
  )
}
