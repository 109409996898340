import type {CsvTab, PdfTab, Tab} from '@/types'
import {type PayloadAction, createSlice} from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    chatPaneTabs: [] as Tab[],
    selectedChatPaneTab: 0,
    selectedSplitPaneTab: 0,
    showSidebar: true,
    splitPaneTabs: [] as (CsvTab | PdfTab)[],
  },
  reducers: {
    closeSplitPaneTab: (state, action) => {
      state.splitPaneTabs.splice(action.payload, 1)
    },
    openInSplitPane: (state, action: PayloadAction<PdfTab | CsvTab>) => {
      state.splitPaneTabs.push(action.payload)
    },
    setSplitPanes: (state, action: PayloadAction<PdfTab[]>) => {
      state.splitPaneTabs = action.payload
    },
    setSelectedChatPaneTab: (state, action) => {
      state.selectedChatPaneTab = action.payload
    },
    setSelectedSplitPaneTab: (state, action) => {
      state.selectedSplitPaneTab = action.payload
    },
    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar
    },
  },
})
