import {
  type ChatFileResponse,
  type ChatResponse,
  type DataBucketFileResponse,
  type PromptResponse,
  api,
  useCreateMessageMutation,
  useCreateNewChatInputMutation,
} from '@/api'
import {APP_STATE, ROLE, TAG_TYPE} from '@/constants'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {IconButton, cn} from '@gicortex/nucleus'
import {ArrowUp} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'

export function focusPrompt() {
  const element = document.querySelector(
    '[data-prompt-input]',
  ) as HTMLTextAreaElement
  if (element) {
    element.focus()
  }
}

export const SendButton = ({
  disabled,
  prompt,
  className,
}: {
  disabled?: boolean
  prompt?: PromptResponse
  className?: string
}) => {
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()
  const [sendRagQuery] = useCreateMessageMutation()
  const [sendChatQuery] = useCreateNewChatInputMutation()

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      // get selected files
      const files = current.selectedFiles.map(
        (file: Partial<DataBucketFileResponse>) =>
          ({
            // @ts-ignore
            bucket: file.bucketName,
            path: file.path,
          }) as ChatFileResponse,
      )
      // add the message to the chat
      const userMessage: Partial<ChatResponse> = {
        id: crypto.randomUUID(),
        source: ROLE.USER,
        message: current.prompt || prompt?.prompt,
        // @ts-ignore
        files,
        createdAt: Date(),
        updatedAt: Date(),
      }

      if (current.historyId) {
        userMessage.historyId = current.historyId
      }
      dispatch(currentSlice.actions.addMessage(userMessage))
      // show the loading indicator
      dispatch(
        currentSlice.actions.setAppState(APP_STATE.WAITING_FOR_AGENT_RESPONSE),
      )
      focusPrompt()
      dispatch(currentSlice.actions.resetPrompt())
      const sourceIds = current.selectedFiles.map((file) => file.sourceId)
      const userPrompt = (current.prompt || prompt?.prompt) as string
      let agentMessage = null
      if (sourceIds.length > 0) {
        agentMessage = await sendRagQuery({
          requestMessage: {
            from: ROLE.User,
            historyId: current.historyId,
            prompt: userPrompt,
            sourceIds: current.selectedFiles.map((file) => file.sourceId),
          },
        }).unwrap()
      } else {
        agentMessage = await sendChatQuery({
          createChatRequest: {
            input: userPrompt,
            historyId: current.historyId,
            modelType: 'fast',
          },
        }).unwrap()
      }
      dispatch(api.util.invalidateTags([TAG_TYPE.HISTORY]))
      dispatch(
        currentSlice.actions.addMessage({
          ...agentMessage,
          source: ROLE.AGENT,
        }),
      )
      if (!current.historyId) {
        dispatch(currentSlice.actions.setHistoryId(agentMessage.historyId))
      }
    } catch (error) {
      console.error(error)
      // show error toast
    } finally {
      // hide the loading indicator
      dispatch(
        currentSlice.actions.setAppState(APP_STATE.WAITING_FOR_USER_INPUT),
      )
    }
  }
  return (
    <IconButton
      className={cn(
        'disabled:bg-red-100 disabled:cursor-not-allowed enabled:bg-gray-200 rounded-full',
        className,
      )}
      disabled={disabled}
      icon={ArrowUp}
      onClick={handleClick}
      tooltip='Send Prompt'
    />
  )
}

export const SendMessageButton = ({disabled}: {disabled: boolean}) => {
  const current = useSelector((state: RootState) => state.current)
  const isSendButtonDisabled =
    disabled ||
    current.prompt === '' ||
    current.appState === APP_STATE.WAITING_FOR_AGENT_RESPONSE
  return (
    <div className='rounded-r-full'>
      <SendButton disabled={isSendButtonDisabled} />
    </div>
  )
}
