import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from '@/constants'
import {authUtil} from '@/utils/auth'
import {useEffect} from 'react'
import {Navigate, useSearchParams} from 'react-router-dom'

export const CallbackPage = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const accessToken = searchParams.get(ACCESS_TOKEN_KEY)
    const refreshToken = searchParams.get(REFRESH_TOKEN_KEY)
    authUtil.setTokens({accessToken, refreshToken})
  })

  return <Navigate to='/' />
}
