import {LoginButton} from '@/components/login-button'
import {KvLogo, Layout, PlaceCenter, Row} from '@gicortex/nucleus'

export const LoginPage = () => (
  <Layout
    footer
    showSidebar={false}
  >
    <PlaceCenter>
      <Row className='flex flex-wrap justify-between w-56'>
        <KvLogo />
        <LoginButton />
      </Row>
    </PlaceCenter>
  </Layout>
)
