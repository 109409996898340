import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from '@/constants'

interface TokenPayload {
  accessToken: string | null
  refreshToken: string | null
}

const setTokens = ({accessToken, refreshToken}: TokenPayload): void => {
  if (accessToken) {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  }
  if (refreshToken) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  }
}

const removeTokens = (): void => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(REFRESH_TOKEN_KEY)
}

const getTokens = (): TokenPayload => {
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY)
  const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY)
  return {accessToken, refreshToken}
}

export const authUtil = {
  setTokens,
  removeTokens,
  getTokens,
}
