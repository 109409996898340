import {layoutSlice} from '@/store/layout'

import {ToggleSidebarButton as NucleusToggleSidebarButton} from '@gicortex/nucleus'
import {useDispatch} from 'react-redux'

export const ToggleSidebarButton = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(layoutSlice.actions.toggleSidebar())
  }

  return <NucleusToggleSidebarButton onClick={handleClick} />
}
