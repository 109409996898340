import {useCreateNewPromptMutation} from '@/api'
import {
  Form,
  FormModal,
  nonEmptyString,
  optionalString,
  useToast,
} from '@gicortex/nucleus'

interface FormValues {
  name: string
  description: string
  prompt: string
}

export const CreatePromptModal = () => {
  const [createPrompt] = useCreateNewPromptMutation()
  const {showSuccessToast, showErrorToast} = useToast()

  const handleSubmit = async ({name, description, prompt}: FormValues) => {
    try {
      const result = await createPrompt({
        createPromptModel: {
          name,
          description,
          prompt,
        },
      })
      // @ts-ignore
      // if (response.type === RESPONSE_TYPE.ERROR) {
      //   // @ts-ignore
      //   throw new Error(response.detail)
      // }
      showSuccessToast('Prompt created successfully')
      return result
    } catch (error) {
      // @ts-ignore
      showErrorToast(error.data.detail)
    }
  }

  return (
    <FormModal
      modalProps={{size: 'm'}}
      title='Create Prompt'
      onSubmit={handleSubmit}
      defaultValues={{
        name: '',
        description: '',
        prompt: '',
      }}
      schema={() => ({
        name: nonEmptyString('Name'),
        description: optionalString(),
        prompt: nonEmptyString('Prompt'),
      })}
    >
      <Form.Input
        autoFocus
        name='name'
        label='Name'
        type='text'
      />
      <Form.Textarea
        name='description'
        label='Description'
      />
      <Form.Textarea
        label='Prompt'
        name='prompt'
      />
    </FormModal>
  )
}
