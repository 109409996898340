import {SelectedFiles} from '@/components/selected-files'
import {SendMessageButton} from '@/components/send-message-button'
import {UploadFileButton} from '@/components/upload-file-button'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {Col, Row, Textarea} from '@gicortex/nucleus'
import {debounce} from 'lodash'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

export const Prompt = () => {
  const reduxPrompt = useSelector((state: RootState) => state.current.prompt)
  const uploadingFiles = useSelector(
    (state: RootState) => state.current.uploadingFiles,
  )
  const [localPrompt, setLocalPrompt] = useState(reduxPrompt)
  const dispatch = useDispatch()

  useEffect(() => {
    setLocalPrompt(reduxPrompt)
  }, [reduxPrompt])

  const debouncedDispatch = useCallback(
    debounce((value: string) => {
      dispatch(currentSlice.actions.setPrompt(value))
    }, 300),
    [],
  )

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const prompt = e.target.value
    setLocalPrompt(prompt)
    debouncedDispatch(prompt)
  }

  return (
    <Col className='gap-2 items-center max-w-screen-md mx-auto w-full'>
      <SelectedFiles />
      <div className='px-8 w-full'>
        <Textarea
          className='self-center w-full'
          data-prompt-input
          dynamicHeight
          name='prompt'
          onChange={handleChange}
          rows={5}
          textAreaClassName='bg-gray-50 border-0 focus-visible:outline-none max-h-64 p-4 resize-none rounded-none !rounded-tl-xl !rounded-tr-xl w-full'
          value={localPrompt}
        />
        <Row className='bg-gray-100 gap-2 justify-end justify-self-center items-end p-2 rounded-bl-xl rounded-br-xl w-full '>
          <UploadFileButton />
          <SendMessageButton disabled={uploadingFiles.length > 0} />
        </Row>
      </div>
    </Col>
  )
}
