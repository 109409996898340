import {SIDEBAR_TABS} from '@/constants'
import {History} from '@/features/history'
import {Prompts} from '@/features/prompts'
import {Vaults} from '@/features/vaults'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {
  IconButton,
  KvLogo,
  Sidebar as NucleusSidebar,
  Row,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  cn,
} from '@gicortex/nucleus'
import {Files, HistoryIcon, Library, type LucideIcon} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'
import {ToggleSidebarButton} from '../toggle-sidebar-button'

interface TabType {
  id: number
  icon: LucideIcon
  label: string
  onClick: () => void
  panel: React.ReactNode
  selected: boolean
}

export const Sidebar = () => {
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()

  const tabs = [
    {
      id: 1,
      icon: HistoryIcon,
      label: 'History',
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SIDEBAR_TABS.HISTORY,
        )
        dispatch(action)
      },
      panel: <History />,
      selected: current.selectedSidebarTab === SIDEBAR_TABS.HISTORY,
    },
    {
      id: 2,
      icon: Files,
      label: 'Files',
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SIDEBAR_TABS.VAULTS,
        )
        dispatch(action)
      },
      panel: <Vaults />,
      selected: current.selectedSidebarTab === SIDEBAR_TABS.VAULTS,
    },
    {
      id: 3,
      icon: Library,
      label: 'Prompts',
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SIDEBAR_TABS.PROMPT_LIBRARY,
        )
        dispatch(action)
      },
      panel: <Prompts />,
      selected: current.selectedSidebarTab === SIDEBAR_TABS.PROMPT_LIBRARY,
    },
  ]

  return (
    <TabGroup>
      <NucleusSidebar
        appLogo={<KvLogo size='m' />}
        header={<SidebarHeader tabs={tabs} />}
        toggleSidebarButton={<ToggleSidebarButton />}
      >
        <TabPanels className='overflow-y-auto mt-0'>
          {tabs.map((tab) => (
            <TabPanel
              className='!py-0'
              key={tab.id}
            >
              {tab.panel}
            </TabPanel>
          ))}
        </TabPanels>
      </NucleusSidebar>
    </TabGroup>
  )
}

export const SidebarHeader = ({tabs}: {tabs: TabType[]}) => (
  <TabList className='flex justify-between p-2 ml-auto'>
    <Row>
      {tabs.map((tab) => (
        <Tab
          as='div'
          className='focus-visible:outline-none p-0 data-[selected]:border-b-0'
          key={tab.id}
        >
          <Row>
            <IconButton
              className={cn(tab.selected && 'bg-gray-200')}
              icon={tab.icon}
              size='m'
              tooltip={tab.label}
              onClick={tab.onClick}
            />
          </Row>
        </Tab>
      ))}
    </Row>
  </TabList>
)
