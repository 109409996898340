import type {Item} from '@/features/vaults'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {XIconButton} from '@gicortex/nucleus'
import {useDispatch, useSelector} from 'react-redux'

export const SelectedFiles = () => {
  const files = useSelector((state: RootState) => state.current.selectedFiles)
  const dispatch = useDispatch()

  const handleUnselectFile = (file: Item) => {
    dispatch(currentSlice.actions.removeFile(file))
  }

  return (
    <div className='flex flex-wrap gap-2'>
      {files.map((file) => (
        <div
          className='bg-gray-50 text-gray-700 flex items-center pl-3 rounded-full'
          key={file.path}
        >
          <span className='mr-2 max-w-[100px] sm:max-w-[150px] md:max-w-[200px] lg:max-w-[250px] xl:max-w-[300px] truncate'>
            {file.name}
          </span>
          <XIconButton
            className='rounded-full'
            onClick={() => handleUnselectFile(file)}
          />
        </div>
      ))}
    </div>
  )
}
