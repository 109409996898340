import {api} from '@/api'
import {authUtil} from '@/utils/auth'
import {useEffect} from 'react'

export const LogoutPage = () => {
  const {data: config} = api.useGetConfigsQuery()

  useEffect(() => {
    if (config) {
      authUtil.removeTokens()
      window.location.href = `${config.logoutUrl}?origin=${window.location.origin}`
    }
  }, [config])

  return 'Logging out...'
}
