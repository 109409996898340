import {APP_STATE} from '@/constants'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {IconButton} from '@gicortex/nucleus'
import {SquarePen} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'

export const NewChatButton = () => {
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()

  return (
    <IconButton
      className='disabled:bg-red-100 disabled:cursor-not-allowed'
      disabled={current.appState === APP_STATE.WAITING_FOR_AGENT_RESPONSE}
      icon={SquarePen}
      onClick={() => dispatch(currentSlice.actions.reset())}
      size='m'
      tooltip='New Chat'
    />
  )
}
