import {api} from '@/api'
import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query/react'
import {currentSlice} from './current'
import {historySlice} from './history'
import {layoutSlice} from './layout'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  reducer: {
    [api.reducerPath]: api.reducer,
    [currentSlice.name]: currentSlice.reducer,
    [historySlice.name]: historySlice.reducer,
    [layoutSlice.name]: layoutSlice.reducer,
  },
})

setupListeners(store.dispatch)
