import {api} from '@/api'
import {IconButton} from '@gicortex/nucleus'
import {LogIn} from 'lucide-react'

export const LoginButton = () => {
  const {data: config, isLoading, isError} = api.useGetConfigsQuery()

  const handleClick = () => {
    if (config) {
      window.location.replace(
        `${config.serverUrl}${config.callbackPath}?origin=${window.location.origin}`,
      )
    }
  }

  return (
    <IconButton
      loading={isLoading}
      disabled={isLoading || isError}
      icon={LogIn}
      onClick={handleClick}
      size='m'
      tooltip='Login'
    />
  )
}
