import type {RootState} from '@/types'
import {cn} from '@gicortex/nucleus'
import {useSelector} from 'react-redux'
import {Footer} from './footer'

interface MainPaneProps {
  children: React.ReactNode
}

export const MainPane = ({children}: MainPaneProps) => {
  const layout = useSelector((state: RootState) => state.layout)

  return (
    <main
      className={cn(
        'bg-white grid grid-rows-[1fr_auto] h-full overflow-x-auto',
        layout.splitPaneTabs.length > 0 ? '' : 'rounded-r-lg',
        !layout.showSidebar && 'rounded-s-lg',
      )}
    >
      {children}
      <Footer />
    </main>
  )
}
