import {type ChatHistoryLiteResponse, api} from '@/api'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {
  Col,
  DeleteModalButton,
  ErrorState,
  LoadingState,
  Row,
  cn,
  useToast,
} from '@gicortex/nucleus'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {useDispatch, useSelector} from 'react-redux'
dayjs.extend(isBetween)

const currentDate = dayjs()

const Section = ({
  title,
  chats = [],
}: {
  title: string
  chats?: ChatHistoryLiteResponse[]
}) => {
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()
  const [deleteChatHistory] = api.useDeleteChatHistoryMutation()
  const [getChatHistory] = api.useLazyGetChatHistoryQuery()

  const handleClick = async (chat: ChatHistoryLiteResponse) => {
    dispatch(currentSlice.actions.setHistoryId(chat.id))
    const messages = await getChatHistory({id: chat.id}).unwrap()
    dispatch(currentSlice.actions.setMessages([...messages].reverse()))
  }

  interface DeleteChatModalProps {
    chat: ChatHistoryLiteResponse
  }
  const DeleteChatButton = ({chat}: DeleteChatModalProps) => {
    const {showErrorToast} = useToast()
    return (
      <DeleteModalButton
        title='Delete Chat'
        className={cn('p-0', 'hidden group-hover:block')}
        content={
          <p>
            Are you sure you want to delete <strong>{chat.name}</strong>?
          </p>
        }
        tooltip='Delete Chat'
        onSubmit={async () => {
          const {error} = await deleteChatHistory({id: chat.id})
          if (error) {
            showErrorToast('Failed to delete chat history')
            return console.error(error)
          }
          dispatch(currentSlice.actions.setMessages([]))
        }}
      />
    )
  }

  return (
    <Col className='gap-2'>
      <h6 className='px-2 pt-2'>{title}</h6>
      <Col>
        {chats.length === 0 ? (
          <p className='text-sm p-2'>None</p>
        ) : (
          chats
            .map((chat) => (
              <Row
                className={cn(
                  'group capitalize text-sm p-2 hover:bg-indigo-100 rounded-lg hover:cursor-pointer justify-between',
                  chat.id === current.historyId && 'bg-gray-200',
                )}
                key={chat.id}
                onClick={() => handleClick(chat)}
              >
                <p className='truncate'>{chat.name}</p>
                <DeleteChatButton chat={chat} />
              </Row>
            ))
            .reverse()
        )}
      </Col>
    </Col>
  )
}

const Chats = ({chats}: {chats: ChatHistoryLiteResponse[]}) => {
  const today = chats.filter((chat) =>
    dayjs(chat.createdAt).isSame(currentDate, 'day'),
  )
  const yesterday = chats.filter((chat) =>
    dayjs(chat.createdAt).isSame(currentDate.subtract(1, 'day'), 'day'),
  )
  const thisWeek = chats.filter((chat) =>
    dayjs(chat.createdAt).isBetween(
      currentDate.subtract(7, 'day').startOf('day'),
      currentDate.subtract(2, 'day').endOf('day'),
      'day',
      '[]',
    ),
  )

  const thisMonth = chats.filter((chat) =>
    dayjs(chat.createdAt).isBetween(
      currentDate.subtract(30, 'day').startOf('day'),
      currentDate.subtract(7, 'day').endOf('day'),
      'day',
      '[]',
    ),
  )
  const olderThanThisMonth = chats.filter((chat) =>
    dayjs(chat.createdAt).isBefore(
      currentDate.subtract(30, 'day').startOf('day'),
    ),
  )

  return (
    <>
      <Section
        title='Today'
        chats={today}
      />
      <Section
        title='Yesterday'
        chats={yesterday}
      />
      <Section
        title='This Week'
        chats={thisWeek}
      />
      <Section
        title='This Month'
        chats={thisMonth}
      />
      <Section
        title='Older than this month'
        chats={olderThanThisMonth}
      />
    </>
  )
}

export const History = () => {
  const {data: chats = [], isLoading, error} = api.useGetChatHistoriesQuery()

  return (
    <Col className='gap-8 p-3'>
      {isLoading ? (
        <Row className='justify-center pt-2'>
          <LoadingState />
        </Row>
      ) : error ? (
        <Row className='justify-center pt-2'>
          <ErrorState />
        </Row>
      ) : (
        <Chats chats={chats} />
      )}
    </Col>
  )
}
