export const API_URL = import.meta.env.VITE_API_URL

export const APP_STATE = {
  WAITING_FOR_AGENT_RESPONSE: 'WAITING_FOR_AGENT_RESPONSE',
  WAITING_FOR_USER_INPUT: 'WAITING_FOR_USER_INPUT',
} as const

export const CODE_LANGUAGE_SUBSET = [
  'python',
  'javascript',
  'java',
  'go',
  'bash',
  'c',
  'cpp',
  'csharp',
  'css',
  'diff',
  'graphql',
  'json',
  'kotlin',
  'less',
  'lua',
  'makefile',
  'markdown',
  'objectivec',
  'perl',
  'php',
  'php-template',
  'plaintext',
  'python-repl',
  'r',
  'ruby',
  'rust',
  'scss',
  'shell',
  'sql',
  'swift',
  'typescript',
  'vbnet',
  'wasm',
  'xml',
  'yaml',
]

export const RESPONSE_TYPE = {
  ERROR: 'error',
} as const

export const ROLE = {
  Assistant: 'assistant',
  System: 'system',
  User: 'user',
  // Deprecated
  AGENT: 'ai',
  USER: 'user',
} as const

export const SIDEBAR_TABS = {
  HISTORY: 'History',
  VAULTS: 'Vaults',
  PROMPT_LIBRARY: 'Prompt Library',
} as const

export const SUGGESTION_GENRE = {
  EDUCATION: 'EDUCATION',
  FUN: 'FUN',
  IDEATION: 'IDEATION',
  WORK: 'WORK',
} as const

export const TAG_TYPE = {
  HISTORY: 'HISTORY',
  PROMPT: 'PROMPT',
  DATABUCKET: 'DATABUCKET',
} as const

export const STORAGE_PROTOCOL = {
  AWS: 's3://',
} as const

export const ACCESS_TOKEN_KEY = 'accessToken'

export const REFRESH_TOKEN_KEY = 'refreshToken'
