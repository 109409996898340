import {
  type PromptResponse,
  useDeletePromptByIdMutation,
  useGetAllPromptsQuery,
} from '@/api'
import {CreatePromptModal} from '@/components/create-prompt-modal'
import {SendButton, focusPrompt} from '@/components/send-message-button'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {
  Button,
  Col,
  DeleteModalButton,
  ErrorState,
  Icon,
  LoadingState,
  Row,
  cn,
  useModal,
  useToast,
} from '@gicortex/nucleus'
import {Plus} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'

const Item = ({prompt}: {prompt: PromptResponse}) => {
  const current = useSelector((state: RootState) => state.current)
  const [deletePrompt] = useDeletePromptByIdMutation()
  const {showSuccessToast, showErrorToast} = useToast()
  const dispatch = useDispatch()

  const DeletePromptButton = ({prompt}: {prompt: PromptResponse}) => {
    const handleDelete = async (prompt: PromptResponse) => {
      try {
        await deletePrompt({id: prompt.id})
        showSuccessToast('Prompt deleted successfully')
      } catch (error) {
        showErrorToast('Failed to delete prompt')
        console.error(error)
      }
    }

    return (
      <DeleteModalButton
        className='p-0 hidden group-hover:block'
        content={
          <p>
            Are you sure you want to delete <strong>{prompt.name}</strong>?
          </p>
        }
        title='Delete Prompt'
        tooltip='Delete Prompt'
        onSubmit={() => handleDelete(prompt)}
      />
    )
  }

  return (
    <Row
      className={cn(
        'group text-sm p-2 hover:bg-indigo-100 rounded-lg hover:cursor-pointer flex justify-between items-center',
        prompt.prompt === current.prompt && 'bg-gray-200',
      )}
      key={prompt.id}
      onClick={() => {
        dispatch(currentSlice.actions.setPrompt(prompt.prompt))
        focusPrompt()
      }}
    >
      {prompt.name}
      <div className='flex items-center space-x-2 ml-auto'>
        <SendButton
          prompt={prompt}
          disabled={!prompt.prompt}
          className='p-0 hidden group-hover:block'
        />
        <DeletePromptButton prompt={prompt} />
      </div>
    </Row>
  )
}

export const Prompts = () => {
  const {openModal} = useModal()
  const {data: prompts = [], isLoading, error} = useGetAllPromptsQuery()

  const handleClick = () => {
    openModal(<CreatePromptModal />)
  }

  return (
    <Col>
      <Col className='border-b p-2'>
        <Button
          className='bg-indigo-100'
          onClick={handleClick}
        >
          <Row className='gap-1 justify-center text-xs'>
            <Icon component={Plus} />
            Create Prompt
          </Row>
        </Button>
      </Col>
      {isLoading ? (
        <Row className='justify-center pt-4'>
          <LoadingState />
        </Row>
      ) : error ? (
        <Row className='justify-center pt-2'>
          <ErrorState />
        </Row>
      ) : (
        <Col className='p-2'>
          {prompts.length === 0 ? (
            <Row className='justify-center pt-4'>
              <p>No Prompts found</p>
            </Row>
          ) : (
            prompts
              .map((prompt) => (
                <Item
                  key={prompt.id}
                  prompt={prompt}
                />
              ))
              .reverse()
          )}
        </Col>
      )}
    </Col>
  )
}
