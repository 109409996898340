import {
  type FileStatus,
  useCreateDatabucketFileIndexMutation,
  useDeleteDatabucketFileMutation,
  useDownloadDatabucketFileMutation,
  useGetDatabucketFilesQuery,
} from '@/api'
import {RefreshVaultsButton} from '@/components/refresh-vaults-button'
import {UploadFilesModal} from '@/components/upload-files-modal'
import {STORAGE_PROTOCOL} from '@/constants'
import {currentSlice} from '@/store/current'
import {RefreshIconButton} from '@gicortex/nucleus'
import {Dot, Eye} from 'lucide-react'

import {layoutSlice} from '@/store/layout'
import type {CsvTab, PdfTab} from '@/types'
import {fileUtil} from '@/utils/file'
import {
  Button,
  Col,
  DeleteModalButton,
  ErrorState,
  Icon,
  LoadingState,
  Row,
  cn,
  useModal,
  useToast,
} from '@gicortex/nucleus'
import {Upload} from 'lucide-react'
import {useDispatch} from 'react-redux'

export interface Item {
  bucketName: string
  id: string
  name: string
  path: string
  sourceId: string
  status: FileStatus
}

const Section = ({
  title,
  items,
}: {
  title: string
  items: Item[]
}) => {
  const [retriggerFile, {isLoading}] = useCreateDatabucketFileIndexMutation()
  const {refetch} = useGetDatabucketFilesQuery()
  const [deleteItem] = useDeleteDatabucketFileMutation()
  const {showSuccessToast, showErrorToast} = useToast()
  const dispatch = useDispatch()
  const [getDownloadUrl] = useDownloadDatabucketFileMutation()

  const DeleteItemButton = ({item}: {item: Item}) => {
    const handleDelete = async (item: Item) => {
      try {
        await deleteItem({
          databucketName: item.bucketName,
          dataBucketRequest: {
            path: item.path,
          },
        })
        showSuccessToast('File deleted successfully')
      } catch (error) {
        showErrorToast('Failed to delete file')
        console.error(error)
      }
    }

    return (
      <DeleteModalButton
        className='p-0 hidden group-hover:block'
        title='Delete File'
        content={
          <p>
            Are you sure you want to delete <strong>{item.name}</strong>?
          </p>
        }
        tooltip='Delete File'
        onSubmit={() => handleDelete(item)}
      />
    )
  }

  const PreviewButton = ({item}: {item: Item}) => {
    const ext = item.name.split('.').pop()
    const type = ext === 'pdf' ? 'pdf' : 'csv'
    const handleClick = async () => {
      const result = await getDownloadUrl({
        databucketName: item.bucketName,
        dataBucketRequest: {
          path: item.sourceId,
        },
      }).unwrap()
      const label = fileUtil.removeUuidPrefix(item.name)
      const tab: PdfTab | CsvTab = {
        label: label.length <= 10 ? label : `${label.slice(0, 10)}...`,
        type,
        url: result.url,
      }
      dispatch(layoutSlice.actions.openInSplitPane(tab))
    }

    return (
      <Button
        className='p-0 hidden group-hover:block'
        onClick={handleClick}
        tooltip='Preview File'
      >
        <Icon component={Eye} />
      </Button>
    )
  }

  const getFileStatusInfo = (status: FileStatus) => {
    switch (status) {
      case 'SUCCESS':
        return {label: 'File Ready', color: 'text-green-500', status: 'SUCCESS'}
      case 'FAILURE':
        return {
          label: 'Upload failed',
          color: 'text-red-500',
          status: 'FAILURE',
        }
      default:
        return {
          label: 'File Pending',
          color: 'text-yellow-500',
          status: 'PENDING',
        }
    }
  }

  return (
    <Col className='break-all'>
      <h6 className='px-2 pt-2 mb-2'>
        {title === 'Personal' ? 'Your Vault' : title}
      </h6>
      {items.map((file) => {
        const {label, color, status} = getFileStatusInfo(file.status)

        return (
          <Row
            className={cn(
              'hover:bg-indigo-100',
              'group text-sm gap-2 justify-between p-2 rounded-lg  hover:cursor-pointer active:bg-indigo-50',
            )}
            key={file.id}
            onClick={() =>
              status === 'SUCCESS' &&
              dispatch(currentSlice.actions.selectFile(file))
            }
          >
            <Row>
              <Icon
                tooltip={label}
                strokeWidth='12'
                component={Dot}
                className={cn(color, 'pointer-events-auto mr-1')}
              />
              {fileUtil.removeUuidPrefix(file.name)}
            </Row>
            <Row className='flex gap-2'>
              {status === 'FAILURE' && (
                <RefreshIconButton
                  isFetching={isLoading}
                  refetch={async () => {
                    await retriggerFile({
                      databucketName: file.bucketName,
                      dataBucketFileIndexRequest: {path: file.path},
                    })
                    refetch()
                  }}
                  tooltip='Retry Upload'
                  className='p-0 hidden group-hover:block'
                />
              )}
              <PreviewButton item={file} />
              <DeleteItemButton item={file} />
            </Row>
          </Row>
        )
      })}
    </Col>
  )
}

export const Vaults = () => {
  const {openModal} = useModal()
  const {data: files = [], isLoading, error} = useGetDatabucketFilesQuery()

  const handleUpload = () => {
    openModal(<UploadFilesModal />)
  }

  const items = files.map((file) => {
    const parts = file.file.split('/')
    const groupId = parts[1]
    const fileName = parts[2]
    const sourceId = `${STORAGE_PROTOCOL.AWS}${file.bucket}/${file.path}`
    return {
      bucketName: file.bucket,
      groupId,
      id: file.path,
      name: fileName,
      path: file.path,
      sourceId,
      status: file.status,
    }
  })

  const groups: {[groupId: string]: Item[]} = {}

  for (const item of items) {
    if (!groups[item.groupId]) {
      groups[item.groupId] = []
    }
    groups[item.groupId].push(item)
  }

  return (
    <Col>
      <Row className='border-b gap-2 p-2'>
        <Button
          className='bg-indigo-100 w-full'
          onClick={handleUpload}
        >
          <Row className='gap-1 justify-center text-xs w-full'>
            <Icon component={Upload} />
            Upload File
          </Row>
        </Button>
        <RefreshVaultsButton />
      </Row>
      {isLoading ? (
        <Row className='justify-center pt-4'>
          <LoadingState />
        </Row>
      ) : error ? (
        <Row className='justify-center pt-2'>
          <ErrorState />
        </Row>
      ) : (
        <Col className='gap-8 p-2'>
          {files.length === 0 ? (
            <Row className='justify-center pt-4'>
              <p>No files found</p>
            </Row>
          ) : (
            Object.entries(groups).map(([groupId, files]) => (
              <Section
                key={groupId}
                title={groupId}
                items={files}
              />
            ))
          )}
        </Col>
      )}
    </Col>
  )
}
