import {api} from '@/api'
import {Chat} from '@/features/chat'
import {Layout} from '@/layout'
import {historySlice} from '@/store/history'
import {ErrorState, LoadingState} from '@gicortex/nucleus'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

export const AppPage = () => {
  const {data: chats, isLoading, error} = api.useGetChatHistoriesQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    if (chats) {
      dispatch(historySlice.actions.setChats(chats))
    }
  })

  if (isLoading) {
    return <LoadingState />
  }

  if (error) {
    return <ErrorState />
  }

  return (
    <Layout>
      <Chat />
    </Layout>
  )
}
