import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
      query: (queryArg) => ({
        url: `/auth`,
        method: "POST",
        body: queryArg.refreshTokenModel,
      }),
    }),
    getChatHistories: build.query<
      GetChatHistoriesApiResponse,
      GetChatHistoriesApiArg
    >({
      query: () => ({ url: `/chatHistories` }),
    }),
    getChatHistory: build.query<
      GetChatHistoryApiResponse,
      GetChatHistoryApiArg
    >({
      query: (queryArg) => ({ url: `/chatHistories/${queryArg.id}` }),
    }),
    deleteChatHistory: build.mutation<
      DeleteChatHistoryApiResponse,
      DeleteChatHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/chatHistories/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    createNewChatInput: build.mutation<
      CreateNewChatInputApiResponse,
      CreateNewChatInputApiArg
    >({
      query: (queryArg) => ({
        url: `/chats`,
        method: "POST",
        body: queryArg.createChatRequest,
      }),
    }),
    getConfigs: build.query<GetConfigsApiResponse, GetConfigsApiArg>({
      query: () => ({ url: `/configs/startup` }),
    }),
    getDatabuckets: build.query<
      GetDatabucketsApiResponse,
      GetDatabucketsApiArg
    >({
      query: () => ({ url: `/databuckets` }),
    }),
    getDatabucketFiles: build.query<
      GetDatabucketFilesApiResponse,
      GetDatabucketFilesApiArg
    >({
      query: () => ({ url: `/databuckets/files` }),
    }),
    uploadDatabucketFiles: build.mutation<
      UploadDatabucketFilesApiResponse,
      UploadDatabucketFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/databuckets/${queryArg.databucketName}/files`,
        method: "POST",
        body: queryArg.dataBucketFileUploadFormMultiPart,
      }),
    }),
    downloadDatabucketFile: build.mutation<
      DownloadDatabucketFileApiResponse,
      DownloadDatabucketFileApiArg
    >({
      query: (queryArg) => ({
        url: `/databuckets/${queryArg.databucketName}/files`,
        method: "PUT",
        body: queryArg.dataBucketRequest,
      }),
    }),
    deleteDatabucketFile: build.mutation<
      DeleteDatabucketFileApiResponse,
      DeleteDatabucketFileApiArg
    >({
      query: (queryArg) => ({
        url: `/databuckets/${queryArg.databucketName}/files`,
        method: "DELETE",
        body: queryArg.dataBucketRequest,
      }),
    }),
    createDatabucketFileIndex: build.mutation<
      CreateDatabucketFileIndexApiResponse,
      CreateDatabucketFileIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/databuckets/${queryArg.databucketName}/files/index`,
        method: "POST",
        body: queryArg.dataBucketFileIndexRequest,
      }),
    }),
    createFolder: build.mutation<CreateFolderApiResponse, CreateFolderApiArg>({
      query: (queryArg) => ({
        url: `/folders`,
        method: "POST",
        body: queryArg.createFolderModel,
      }),
    }),
    getFolders: build.query<GetFoldersApiResponse, GetFoldersApiArg>({
      query: (queryArg) => ({
        url: `/folders`,
        params: { type: queryArg["type"] },
      }),
    }),
    updateResourceFolder: build.mutation<
      UpdateResourceFolderApiResponse,
      UpdateResourceFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/folders`,
        method: "PUT",
        body: queryArg.updateResourceFolderModel,
      }),
    }),
    deleteResourceFolder: build.mutation<
      DeleteResourceFolderApiResponse,
      DeleteResourceFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/folders`,
        method: "DELETE",
        body: queryArg.updateResourceFolderModel,
      }),
    }),
    deleteFolder: build.mutation<DeleteFolderApiResponse, DeleteFolderApiArg>({
      query: (queryArg) => ({
        url: `/folders/${queryArg.folderId}`,
        method: "DELETE",
      }),
    }),
    healthcheck: build.query<HealthcheckApiResponse, HealthcheckApiArg>({
      query: () => ({ url: `/healthcheck` }),
    }),
    createMessage: build.mutation<
      CreateMessageApiResponse,
      CreateMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/messages`,
        method: "POST",
        body: queryArg.requestMessage,
      }),
    }),
    createNewPrompt: build.mutation<
      CreateNewPromptApiResponse,
      CreateNewPromptApiArg
    >({
      query: (queryArg) => ({
        url: `/prompts`,
        method: "POST",
        body: queryArg.createPromptModel,
      }),
    }),
    getAllPrompts: build.query<GetAllPromptsApiResponse, GetAllPromptsApiArg>({
      query: () => ({ url: `/prompts` }),
    }),
    getPromptById: build.query<GetPromptByIdApiResponse, GetPromptByIdApiArg>({
      query: (queryArg) => ({ url: `/prompts/${queryArg.id}` }),
    }),
    updatePromptById: build.mutation<
      UpdatePromptByIdApiResponse,
      UpdatePromptByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/prompts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updatePromptModel,
      }),
    }),
    deletePromptById: build.mutation<
      DeletePromptByIdApiResponse,
      DeletePromptByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/prompts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getUserGroups: build.query<GetUserGroupsApiResponse, GetUserGroupsApiArg>({
      query: () => ({ url: `/userGroups` }),
    }),
    getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
      query: () => ({ url: `/users/me` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type RefreshTokenApiResponse =
  /** status 201 The request has succeeded and a new resource has been created as a result. */ RefreshTokenResponse;
export type RefreshTokenApiArg = {
  refreshTokenModel: RefreshTokenModel;
};
export type GetChatHistoriesApiResponse =
  /** status 200 The request has succeeded. */ ChatHistoryLiteResponse[];
export type GetChatHistoriesApiArg = void;
export type GetChatHistoryApiResponse =
  /** status 200 The request has succeeded. */ ChatResponse[];
export type GetChatHistoryApiArg = {
  id: string;
};
export type DeleteChatHistoryApiResponse =
  /** status 204 There is no content to send for this request, but the headers may be useful.  */ void;
export type DeleteChatHistoryApiArg = {
  id: string;
};
export type CreateNewChatInputApiResponse =
  /** status 200 The request has succeeded. */ ChatResponse;
export type CreateNewChatInputApiArg = {
  createChatRequest: CreateChatRequest;
};
export type GetConfigsApiResponse =
  /** status 200 The request has succeeded. */ ConfigResponse;
export type GetConfigsApiArg = void;
export type GetDatabucketsApiResponse =
  /** status 200 The request has succeeded. */ DataBucketResponse[];
export type GetDatabucketsApiArg = void;
export type GetDatabucketFilesApiResponse =
  /** status 200 The request has succeeded. */ DataBucketFileResponse[];
export type GetDatabucketFilesApiArg = void;
export type UploadDatabucketFilesApiResponse =
  /** status 204 There is no content to send for this request, but the headers may be useful.  */ void;
export type UploadDatabucketFilesApiArg = {
  databucketName: string;
  dataBucketFileUploadFormMultiPart: DataBucketFileUploadFormMultiPart;
};
export type DownloadDatabucketFileApiResponse =
  /** status 201 The request has succeeded and a new resource has been created as a result. */ {
    url: string;
  };
export type DownloadDatabucketFileApiArg = {
  databucketName: string;
  dataBucketRequest: DataBucketRequest;
};
export type DeleteDatabucketFileApiResponse =
  /** status 204 There is no content to send for this request, but the headers may be useful.  */ void;
export type DeleteDatabucketFileApiArg = {
  databucketName: string;
  dataBucketRequest: DataBucketRequest;
};
export type CreateDatabucketFileIndexApiResponse =
  /** status 201 The request has succeeded and a new resource has been created as a result. */ void;
export type CreateDatabucketFileIndexApiArg = {
  databucketName: string;
  dataBucketFileIndexRequest: DataBucketFileIndexRequest;
};
export type CreateFolderApiResponse =
  /** status 200 The request has succeeded. */ FolderResponseModel[];
export type CreateFolderApiArg = {
  createFolderModel: CreateFolderModel;
};
export type GetFoldersApiResponse =
  /** status 200 The request has succeeded. */ FolderResponseModel[];
export type GetFoldersApiArg = {
  type?: FolderType;
};
export type UpdateResourceFolderApiResponse =
  /** status 200 The request has succeeded. */ DataBucketFileResponse[];
export type UpdateResourceFolderApiArg = {
  updateResourceFolderModel: UpdateResourceFolderModel;
};
export type DeleteResourceFolderApiResponse =
  /** status 200 The request has succeeded. */ DataBucketFileResponse[];
export type DeleteResourceFolderApiArg = {
  updateResourceFolderModel: UpdateResourceFolderModel;
};
export type DeleteFolderApiResponse =
  /** status 200 The request has succeeded. */ FolderResponseModel[];
export type DeleteFolderApiArg = {
  folderId: string;
};
export type HealthcheckApiResponse =
  /** status 200 The request has succeeded. */ HealthcheckResponse;
export type HealthcheckApiArg = void;
export type CreateMessageApiResponse =
  /** status 200 The request has succeeded. */ ResponseMessage;
export type CreateMessageApiArg = {
  requestMessage: RequestMessage;
};
export type CreateNewPromptApiResponse =
  /** status 201 The request has succeeded and a new resource has been created as a result. */ PromptResponse;
export type CreateNewPromptApiArg = {
  createPromptModel: CreatePromptModel;
};
export type GetAllPromptsApiResponse =
  /** status 200 The request has succeeded. */ PromptResponse[];
export type GetAllPromptsApiArg = void;
export type GetPromptByIdApiResponse =
  /** status 200 The request has succeeded. */ PromptResponse;
export type GetPromptByIdApiArg = {
  id: string;
};
export type UpdatePromptByIdApiResponse =
  /** status 200 The request has succeeded. */ PromptResponse;
export type UpdatePromptByIdApiArg = {
  id: string;
  updatePromptModel: UpdatePromptModel;
};
export type DeletePromptByIdApiResponse =
  /** status 204 There is no content to send for this request, but the headers may be useful.  */ void;
export type DeletePromptByIdApiArg = {
  id: string;
};
export type GetUserGroupsApiResponse =
  /** status 200 The request has succeeded. */ {
    id: string;
    name: string;
    description: string;
  }[];
export type GetUserGroupsApiArg = void;
export type GetUsersMeApiResponse =
  /** status 200 The request has succeeded. */ UserResponse;
export type GetUsersMeApiArg = void;
export type RefreshTokenResponse = {
  refreshToken: string;
  accessToken: string;
};
export type Error = {
  detail: string;
  status: number;
  type: string;
};
export type RefreshTokenModel = {
  refreshToken: string;
};
export type ChatHistoryLiteResponse = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};
export type Role = "assistant" | "system" | "user";
export type ChatFileResponse = {
  bucket: string;
  path: string;
  file: string;
};
export type ChatResponse = {
  id: string;
  message: string;
  source: Role;
  historyId: string;
  files?: ChatFileResponse[];
  createdAt: string;
  updatedAt: string;
};
export type ChatFile = {
  bucket: string;
  path: string;
};
export type ModelType = "fast" | "balanced" | "performance";
export type CreateChatRequest = {
  input: string;
  historyId?: string;
  files?: ChatFile[];
  modelType: ModelType;
};
export type ConfigResponse = {
  serverUrl: string;
  callbackPath: string;
  logoutUrl: string;
  allowedOrigins: string[];
};
export type DataBucketResponse = {
  name: string;
};
export type FileStatus =
  | "QUEUED"
  | "NOT_STARTED"
  | "MANAGED"
  | "STARTING"
  | "CANCELING"
  | "CANCELED"
  | "SUCCESS"
  | "FAILURE"
  | "STARTED";
export type DataBucketFileResponse = {
  path: string;
  file: string;
  bucket: string;
  size?: number;
  updatedAt?: string;
  status: FileStatus;
};
export type DataBucketFileUploadFormMultiPart = {
  files: Blob | string[];
  groupId?: string;
};
export type DataBucketRequest = {
  path: string;
};
export type DataBucketFileIndexRequest = {
  path: string;
};
export type FolderType = "prompt" | "vault";
export type FolderResponseModel = {
  id: string;
  path: string;
  groupId: string;
  bucket: string;
  type: FolderType;
  updatedAt: string;
  createdAt: string;
};
export type CreateFolderModel = {
  bucket: string;
  groupId: string;
  path: string;
  type: FolderType;
};
export type UpdateResourceFolderModel = {
  folderId: string;
  promptId?: string;
  filePath?: string;
};
export type HealthcheckResponse = {
  status: string;
};
export type Message = {
  id?: string;
  from: Role;
  prompt: string;
};
export type CitationSource = {
  id: string;
  author?: string;
  title?: string;
  url: string;
};
export type Citation = {
  source: CitationSource;
  summary: string;
  pageNumber?: number;
};
export type ResponseMessage = Message & {
  historyId: string;
  answer: string;
  citations?: Citation[];
};
export type RequestMessage = Message & {
  historyId?: string;
  sourceIds?: string[];
  history?: Message[];
};
export type PromptResponse = {
  id: string;
  name: string;
  prompt: string;
  groupId: string;
  description: string;
  tag:
    | {
        key: string;
        value: string;
      }[]
    | null;
  createdAt: string;
  updatedAt: string;
};
export type CreatePromptModel = {
  name: string;
  prompt: string;
  description: string;
  groupId?: string;
};
export type UpdatePromptModel = {
  name: string;
  prompt: string;
  description: string;
};
export type UserResponse = {
  id: string;
  email: string;
  name: string;
  avatar: string;
  userGroups: string[];
  createdAt: string;
  updatedAt: string;
};
export const {
  useRefreshTokenMutation,
  useGetChatHistoriesQuery,
  useLazyGetChatHistoriesQuery,
  useGetChatHistoryQuery,
  useLazyGetChatHistoryQuery,
  useDeleteChatHistoryMutation,
  useCreateNewChatInputMutation,
  useGetConfigsQuery,
  useLazyGetConfigsQuery,
  useGetDatabucketsQuery,
  useLazyGetDatabucketsQuery,
  useGetDatabucketFilesQuery,
  useLazyGetDatabucketFilesQuery,
  useUploadDatabucketFilesMutation,
  useDownloadDatabucketFileMutation,
  useDeleteDatabucketFileMutation,
  useCreateDatabucketFileIndexMutation,
  useCreateFolderMutation,
  useGetFoldersQuery,
  useLazyGetFoldersQuery,
  useUpdateResourceFolderMutation,
  useDeleteResourceFolderMutation,
  useDeleteFolderMutation,
  useHealthcheckQuery,
  useLazyHealthcheckQuery,
  useCreateMessageMutation,
  useCreateNewPromptMutation,
  useGetAllPromptsQuery,
  useLazyGetAllPromptsQuery,
  useGetPromptByIdQuery,
  useLazyGetPromptByIdQuery,
  useUpdatePromptByIdMutation,
  useDeletePromptByIdMutation,
  useGetUserGroupsQuery,
  useLazyGetUserGroupsQuery,
  useGetUsersMeQuery,
  useLazyGetUsersMeQuery,
} = injectedRtkApi;
