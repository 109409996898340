import {
  type ChatResponse,
  type Citation,
  type ResponseMessage,
  useDownloadDatabucketFileMutation,
} from '@/api'
import {CODE_LANGUAGE_SUBSET} from '@/constants'
import {layoutSlice} from '@/store/layout'
import type {PdfTab, Role} from '@/types'
import {Col, Icon, IconButton, Row} from '@gicortex/nucleus'
import {BookOpenCheck, Bot} from 'lucide-react'
import {type DetailedHTMLProps, type HTMLAttributes, memo} from 'react'
import ReactMarkdown from 'react-markdown'
// @ts-ignore
import type {ReactMarkdownProps} from 'react-markdown/lib/ast-to-react'
import {useDispatch} from 'react-redux'
import rehypeHighlight from 'rehype-highlight'
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'

export interface AssistantMessageProps {
  message: (ResponseMessage & {source: Role}) | ChatResponse
}

export function AssistantMessage({message}: AssistantMessageProps) {
  const isHistoricalMessage = 'message' in message

  return (
    <Row className='gap-4 items-start'>
      <Icon
        component={Bot}
        size='m'
        className='shrink-0'
      />
      <Col className='gap-4 w-full'>
        <Content
          content={isHistoricalMessage ? message.message : message.answer}
        />
        {'citations' in message && message.citations && (
          <Citations citations={message.citations} />
        )}
      </Col>
    </Row>
  )
}

const p: React.FC<
  Omit<
    DetailedHTMLProps<
      HTMLAttributes<HTMLParagraphElement>,
      HTMLParagraphElement
    >,
    'ref'
  > &
    ReactMarkdownProps
> = memo(({node, ...props}) => (
  <p className='whitespace-pre-wrap'>{props.children}</p>
))

function Content({content}: {content: string}) {
  return (
    <ReactMarkdown
      className='markdown'
      remarkPlugins={[remarkGfm, [remarkMath]]}
      rehypePlugins={[
        rehypeKatex,
        [
          rehypeHighlight,
          {
            detect: true,
            ignoreMissing: true,
            subset: CODE_LANGUAGE_SUBSET,
          },
        ],
      ]}
      components={{
        // @ts-ignore
        p,
      }}
    >
      {content}
    </ReactMarkdown>
  )
}

function Citations({citations}: {citations: Citation[]}) {
  const [getDownloadUrl] = useDownloadDatabucketFileMutation()
  const dispatch = useDispatch()

  return citations.map((citation, index) => (
    <div
      className='bg-gray-50 pl-4 py-2 pr-2 items-start rounded-lg text-xs'
      key={crypto.randomUUID()}
    >
      <IconButton
        className='float-right mb-2 ml-2'
        key={citation.source.url}
        icon={BookOpenCheck}
        onClick={() => handleViewCitationSource(citation, index + 1)}
        tooltip='View Source'
      />{' '}
      <p className='break-words py-1'>{`${citation.summary.slice(0, 140)}${citation.summary.length > 140 && '...'}`}</p>
    </div>
  ))

  async function handleViewCitationSource(
    citation: Citation,
    citationNumber: number,
  ) {
    const result = await getDownloadUrl({
      databucketName: citation.source.id.split('s3://')[1].split('/')[0],
      dataBucketRequest: {
        path: citation.source.url,
      },
    }).unwrap()
    const pdfTab: PdfTab = {
      label: `Citation ${citationNumber}`,
      type: 'pdf',
      url: `${result.url}#page=${citation.pageNumber}`,
    }
    dispatch(layoutSlice.actions.openInSplitPane(pdfTab))
  }
}
