import {useGetDatabucketsQuery, useUploadDatabucketFilesMutation} from '@/api'
import type {Group} from '@/components/upload-files-modal'
import {currentSlice} from '@/store/current'
import {useToast} from '@gicortex/nucleus'
import {uniqueId} from 'lodash'
import {useDispatch} from 'react-redux'

export const useUploadFiles = () => {
  const [uploadFiles, {isLoading, error}] = useUploadDatabucketFilesMutation()
  const {data: databuckets = []} = useGetDatabucketsQuery()

  const {showPromiseToast} = useToast()
  const dispatch = useDispatch()

  interface HandleUploadFilesOptions {
    withToast?: boolean
  }
  interface HandleUploadFilesArgs {
    files: File[]
    bucketName?: string
    options?: HandleUploadFilesOptions
    group?: Group
  }

  const handleUploadFiles = async ({
    files,
    group,
    bucketName,
    options = {withToast: true},
  }: HandleUploadFilesArgs) => {
    // Apply unique id to each file
    const filesWithId = files.map((file) => ({
      ...file,
      id: uniqueId(),
    }))

    try {
      if (!databuckets.length) {
        throw new Error('No buckets available to upload files')
      }

      if (!bucketName) {
        bucketName = databuckets[0].name
      }

      const filesFormData = new FormData()
      for (const file of files) {
        const uniqueFileName = `${crypto.randomUUID()}.${file.name}`
        filesFormData.append('files', file, uniqueFileName)
        if (group?.id) {
          filesFormData.append('groupId', group.id)
          dispatch(currentSlice.actions.setUploadingGroup(group))
        }
      }

      dispatch(currentSlice.actions.setUploadingFiles(filesWithId))

      const uploadPromise = uploadFiles({
        // TODO get this bucketName dynamically from API when available
        databucketName: bucketName,
        // @ts-expect-error rtk query multipart bug
        // ref: https://github.com/reduxjs/redux-toolkit/issues/3063
        dataBucketFileUploadFormMultiPart: filesFormData,
      })

      if (options.withToast) {
        showFileUploadToast(files, uploadPromise)
      }

      return uploadPromise
    } finally {
      dispatch(
        currentSlice.actions.removeUploadingFiles(
          filesWithId.map((file) => file.id),
        ),
      )
    }
  }

  const showFileUploadToast = async (
    filesToUpload: File[],
    uploadPromise: Promise<unknown>,
  ) => {
    const fileCount = `${filesToUpload.length} file${filesToUpload.length > 1 ? 's' : ''}`
    showPromiseToast({
      promise: uploadPromise,
      content: {
        loading: `Uploading ${fileCount}...`,
        success: `${fileCount} uploaded successfully`,
        error: `Failed to upload ${fileCount}. Please try again.`,
      },
    })
  }

  return {
    error,
    isLoading,
    handleUploadFiles,
    showFileUploadToast,
  }
}
